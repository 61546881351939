import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  ByEmployer,
  Questionary,
  ThemeList,
  ThemeListInfo,
  ThemeListWithWebinarCount,
  ThemeScore,
  ThemeWithWebinarCount,
} from "types/financialhealthcheck";
import { SearchParam } from "types/webinar";

export const FINANCIAL_HEALTH_CHECK = "financialHealthCheck";

export const financialHealthCheckApi = createApi({
  reducerPath: FINANCIAL_HEALTH_CHECK,
  baseQuery,
  tagTypes: ["financialHealthCheckApi", "getThemeResponse"],
  endpoints: (builder) => ({
    getAllActiveThemes: builder.query<ThemeList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_FINANCIAL_HEALTH_CHECKUP,
          method: "GET",
        };
      },
      providesTags: ["financialHealthCheckApi"],
    }),
    submitQuestionary: builder.mutation<ThemeScore, Partial<Questionary>>({
      query: (body: Questionary) => {
        return {
          url: ApiConstant.SUBMIT_QUESTIONARY,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["getThemeResponse"],
    }),
    getThemeResponse: builder.query<ThemeScore, ByEmployer>({
      query: (body) => {
        return {
          url: ApiConstant.GET_THEME_RESPONSE,
          method: "POST",
          body,
        };
      },
      providesTags: ["getThemeResponse"],
    }),
    getThemeOnDashboardResponse: builder.query<ThemeListInfo, ByEmployer>({
      query: (body) => {
        return {
          url: ApiConstant.GET_THEME_EMP_DASHBOARD,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): any => response.data,
      providesTags: ["getThemeResponse"],
    }),
    getActiveThemesWithWebinar: builder.query<
      ThemeListWithWebinarCount,
      Partial<SearchParam>
    >({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, EmployerID } = args;
        const body = { pageNo, size, EmployerID };
        return {
          url: ApiConstant.GET_ACTIVE_THEMES_WITH_WEBINARS,
          method: "POST",
          body,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.pageNo) {
          delete newQueryArgs.pageNo;
        }
        if (!newQueryArgs.hasOwnProperty("filter")) {
          newQueryArgs["filter"] = [];
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.data) {
          const filterNewData = newItems.data.filter(
            (res) => !currentCache.data.find((d) => d.ID === res.ID)
          );
          const mergedData = [...currentCache.data, ...filterNewData];
          return {
            ...currentCache,
            ...newItems,
            data: mergedData,
          };
        }
        return newItems;
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): any => response.data,
      providesTags: ["getThemeResponse"],
    }),
  }),
});

export const {
  useGetAllActiveThemesQuery,
  useGetActiveThemesWithWebinarQuery,
  useSubmitQuestionaryMutation,
  useGetThemeResponseQuery,
  useGetThemeOnDashboardResponseQuery,
} = financialHealthCheckApi;
