import { createSlice } from "@reduxjs/toolkit";

import { FilterType } from "../../types/filter";

const initialState: FilterType = {
  employer: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
    EmployerID: "",
    RoleID: "",
  },
  webminer: {
    pageNo: 0,
    size: 0,
    filter: [],
    EmployerID: ""
  },
  date:{
    StartDate:new Date(),
    EndDate:new Date(),
  }
};

const filter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setEmployerFilterState(state, action) {
      state.employer = action.payload;
    },
    setAllWebminerFilterState(state, action) {
      state.webminer = action.payload;
    },
    setDateFilterState(state, action) {
      state.date = action.payload;
    },
  },
});

export default filter.reducer;

export const { setEmployerFilterState,setDateFilterState } = filter.actions;
