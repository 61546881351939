import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import EmployeeList from "./EmployeeList";
import LoadingButton from "@mui/lab/LoadingButton";
import fileTobase64 from "helper/fileTobase64";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import {
  useBulkImportEmployeeMutation,
  useLazyDownloadSampleEmployeeFileQuery,
} from "store/reducers/employee";
import modifyObject from "helper/modifyObject";
import { enqueueSnackbar } from "notistack";
import { getAuthInfo } from "selectors/auth";
import { useSelector } from "store";
import allowNullValue from "helper/allowNullValue";
import { DownloadOutlined } from "@ant-design/icons";
import downloadFile from "helper/downloadFile";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="custom-tabs-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="p-0" sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const validationSchema = yup.object({
  EmployeeData: yup.string().required("File is required"),
  FileType: yup.string().required("File type is required"),
});

const OrganizationAdmin = ({ activeTab }: { activeTab?: number }) => {
  const auth = useSelector((state) => getAuthInfo(state));
  const [value, setValue] = useState(activeTab ?? 0);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [bulkImport, { isError, isSuccess, error, isLoading }] =
    useBulkImportEmployeeMutation();

  const [trigger] = useLazyDownloadSampleEmployeeFileQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const openImportFileDialog = () => {
    setOpenDialog(true);
  };

  const closeImportFileDialog = () => {
    setOpenDialog(false);
    setFile(null);
    formik.setFieldValue("FileType", "");
  };

  const initialValues = {
    FileType: "",
    EmployerId: "",
    EmployeeData: "",
    FileName: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      onBulImportYes();
    },
  });

  const { values, handleSubmit } = formik;

  useEffect(() => {
    if (isError || isSuccess) {
      enqueueSnackbar(
        (error as string | (string & any[])) || "File upload successful",
        {
          variant: isSuccess ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 5000,
        }
      );
    }
  }, [error, isError, isSuccess]);

  const onBulImportYes = async () => {
    const saveValues = allowNullValue(values);
    await bulkImport(
      modifyObject({
        ...saveValues,
        EmployerId: auth?.user?.EmployerID,
      })
    );
    formik.resetForm();
    setFile(null);
    setOpenDialog(false);
  };

  const fileUploader = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0] instanceof File) {
      try {
        const file = (await fileTobase64(e.target.files[0])) as string;
        const fileName = e.target.files[0].name;
        const fileExtension = fileName
          .split(".")
          .pop()
          ?.toLowerCase() as string;
        if (["csv", "xls", "xlsx"].includes(fileExtension)) {
          setFile(e.target.files[0]);
          formik.setFieldValue("EmployeeData", file.split(",")[1]);
          formik.setFieldValue("FileName", e.target.files[0].name);
        } else {
          enqueueSnackbar(
            "Invalid file type. Please upload a CSV, XLS, or XLSX file.",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    } else {
      console.log(null);
    }
  };

  const downloadSampleFile = async () => {
    if (formik.values.FileType) {
      const { data } = await trigger({ ImportType: formik.values.FileType });
      const sampleFileLink = data?.data.fileData
        ? `data:text/csv;base64,${data?.data.fileData}`
        : "";
      const sampleFileName = data?.data?.fileName || "download";
      downloadFile(sampleFileLink, sampleFileName);
    }
  };

  const fileTypeItems = [
    { value: "", label: "Select File" },
    { value: "leavers", label: "Leavers file" },
    { value: "lgps", label: "LGPS Member file" },
    { value: "existing-shared-cost", label: "Existing Shared Cost AVC file" },
    { value: "optin-optout", label: "Opt In / Opt Out File" },
    { value: "standard", label: "Existing Standard AVC file" },
  ];

  console.log(formik.touched.EmployeeData, formik.errors.EmployeeData);

  return (
    <Box className="product-list-section section">
      <Container className="custom-container">
        <Grid container className="custom-row">
          <Grid item xs={12} className="custom-col">
            <Card className="custom-card profile-card">
              <CardContent className="card-content p-0">
                <div className="card-header d-flex align-items-center mb-4 pb-3">
                  <div className="card-title flex-fill">
                    <Typography variant="h1">Manage Employees</Typography>
                  </div>
                  <div className="card-action text-end">
                    {auth?.user?.EmployerName !== "NHS" && (
                      <Button
                        className="btn btn-secondary btn-md"
                        onClick={openImportFileDialog}
                      >
                        <i className="mmm-import"></i>
                        <span className="d-none d-sm-flex">Import file</span>
                      </Button>
                    )}
                  </div>
                </div>
                <div className="employee-detail">
                  <Box className="custom-tabs p-0">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="custom-tabs-list"
                    >
                      <Tab
                        className="custom-tabs-item"
                        label="Employees"
                        {...a11yProps(0)}
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <EmployeeList />
                    </TabPanel>
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* import file dialog */}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Dialog
            onClose={closeImportFileDialog}
            open={openDialog}
            className="custom-dialog main-wrapper"
          >
            <DialogTitle className="custom-dialog-title p-0 d-flex justify-content-between">
              <span>Import your file here</span>
              <Button
                className="dialog-close p-0"
                onClick={closeImportFileDialog}
              >
                <i className="mmm-cancel"></i>
              </Button>
            </DialogTitle>
            <Box className="custom-form">
              <div className="form-block">
                <Grid container className="custom-row">
                  <Grid xs={12} className="custom-col">
                    <FormControl className="form-group mb-3 mb-md-4 select-picker d-flex flex-fill">
                      <InputLabel
                        className="form-label mb-2"
                        htmlFor="FileType"
                      >
                        Select file type
                        <Typography component="span" color="#ff0000">
                          *
                        </Typography>
                      </InputLabel>
                      <Select
                        value={formik.values.FileType}
                        name="FileType"
                        id="FileType"
                        className="form-control"
                        onChange={formik.handleChange}
                      >
                        {fileTypeItems.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.FileType && formik.errors.FileType && (
                        <FormHelperText error={true}>
                          {formik.errors.FileType}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {formik.values.FileType && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                          marginLeft: "5px",
                        }}
                      >
                        <DownloadOutlined onClick={downloadSampleFile} />
                        <Typography
                          sx={{ fontSize: "14px", paddingLeft: "10px" }}
                        >
                          Click Here to Download the Sample File
                        </Typography>
                      </Box>
                    )}
                    <FormControl className="form-group mb-3 mb-md-4 select-picker d-flex flex-fill">
                      <InputLabel
                        className="form-label mb-2"
                        htmlFor="ContactType"
                      >
                        CSV File
                        <Typography component="span" color="#ff0000">
                          *
                        </Typography>
                      </InputLabel>
                      <Stack spacing={2} direction={"row"}>
                        <Box sx={{ position: "relative" }}>
                          <label htmlFor="file">
                            <input
                              id="file"
                              name="file"
                              type="file"
                              style={{
                                opacity: "0",
                                zIndex: "999",
                                width: "80px",
                                height: "36px",
                                position: "relative",
                              }}
                              onChange={fileUploader}
                              accept=".csv,.xls,.xlsx"
                            />
                            <LoadingButton
                              type="button"
                              variant="contained"
                              size="medium"
                              component="button"
                              aria-label="add"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 99,
                              }}
                              className="btn btn-secondary btn-md"
                            >
                              Upload
                            </LoadingButton>
                          </label>
                        </Box>
                        <Typography sx={{ ml: "35px !important", pt: "10px" }}>
                          {file?.name}
                        </Typography>
                      </Stack>
                      {formik.touched.EmployeeData &&
                        formik.errors.EmployeeData && (
                          <FormHelperText error={true} sx={{ mt: "10px" }}>
                            {formik.errors.EmployeeData}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="form-block action-btns d-flex align-items-center justify-content-between">
                <Button
                  className="btn btn-link btn-md"
                  onClick={closeImportFileDialog}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="button"
                  className="btn btn-secondary btn-md"
                  loading={isLoading}
                  onClick={() => handleSubmit()}
                  sx={{ mr: "10px" }}
                >
                  Import
                </LoadingButton>
              </div>
            </Box>
          </Dialog>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default OrganizationAdmin;
