import {
  Grid,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  Button,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import modifyObject from "helper/modifyObject";
import { isString } from "lodash";
import { useEffect } from "react";
import { FilterParam } from "types/seminar";

const validationSchema = yup.object({
  filter: yup
    .array()
    .of(
      yup.object().shape({
        columnType: yup.string(),
        condition: yup.string(),
        value1: yup.lazy((val) =>
          isString(val)
            ? yup.string()
            : yup
                .date()
                .when(["columnType", "value2"], {
                  is: (columnType: string, value2: Date | null) =>
                    columnType === "EventDate" && value2,
                  then: (schema) => schema.required("Start Date is required"),
                })
                .nullable()
        ),
        value2: yup
          .date()
          .when(["columnType", "value1"], {
            is: (columnType: string, value1: Date) =>
              columnType === "EventDate" && value1,
            then: (schema) => schema.required("End Date is required"),
          })
          .min(yup.ref("value1"), "End Date Should be more than StartDate")
          .nullable(),
      })
    )
    .test(
      "minlen",
      "minimum one filter property is required",
      (schema) => !!schema?.filter((res) => res.value1 ?? res.value2).length
    ),
});

function customIcon() {
  return <i className="mmm-calendar"></i>;
}

export const Filter = ({
  filters,
  handleFilter,
  open,
  handleClose,
}: {
  filters: FilterParam[];
  handleFilter: (value: FilterParam[]) => void;
  handleClose: () => void;
  open: boolean;
}) => {
  const initialValues = {
    filter: [
      {
        columnType: "Title",
        condition: "contains",
        value1: filters.find((res) => res.columnType === "Title")?.value1 ?? "",
      },
      {
        columnType: "EventDate",
        condition: "between",
        value1: filters.find((res) => res.columnType === "EventDate")?.value1
          ? new Date(
              filters.find((res) => res.columnType === "EventDate")?.value1
            )
          : null,
        value2: filters.find((res) => res.columnType === "EventDate")?.value2
          ? new Date(
              filters.find((res) => res.columnType === "EventDate")?.value2
            )
          : null,
      },
    ],
  };

  const {
    values,
    dirty,
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const filteredProperties = values.filter
        .filter((res) => res.value1)
        .map((res) => modifyObject(res));
      handleFilter(filteredProperties);
    },
    onReset: () => handleFilter([]),
  });

  useEffect(() => {
    setValues({
      filter: [
        {
          columnType: "Title",
          condition: "contains",
          value1:
            filters.find((res) => res.columnType === "Title")?.value1 ?? "",
        },
        {
          columnType: "EventDate",
          condition: "between",
          value1: filters.find((res) => res.columnType === "EventDate")?.value1
            ? new Date(
                filters.find((res) => res.columnType === "EventDate")?.value1
              )
            : null,
          value2: filters.find((res) => res.columnType === "EventDate")?.value2
            ? new Date(
                filters.find((res) => res.columnType === "EventDate")?.value2
              )
            : null,
        },
      ],
    });
  }, [filters, setValues]);

  const findIndex = (key: string) =>
    values.filter.findIndex((res) => res.columnType === key);

  const helperText = (key: string): FormikErrors<FilterParam> | undefined => {
    if (errors.filter && Array.isArray(errors.filter)) {
      return errors.filter[findIndex(key)] as FormikErrors<FilterParam>;
    } else {
      return;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      className="custom-dialog main-wrapper"
    >
      <DialogTitle className="custom-dialog-title p-0 d-flex justify-content-between">
        <span>Filter</span>
        <Button className="dialog-close p-0" onClick={() => handleClose()}>
          <i className="mmm-cancel"></i>
        </Button>
      </DialogTitle>
      <Box className="custom-form">
        <div className="form-block">
          <Grid container className="custom-row">
            <Grid xs={12} className="custom-col">
              <FormControl
                className="form-group mb-4 d-flex flex-fill"
                variant="standard"
              >
                <InputLabel
                  className="form-label mb-2"
                  htmlFor="input-with-icon-adornment"
                >
                  Search By Name
                </InputLabel>
                <TextField
                  className="form-control"
                  placeholder="Search seminar topic"
                  value={values.filter[findIndex("Title")].value1}
                  name={`filter[${[findIndex("Title")]}].value1`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id={`filter[${[findIndex("Title")]}].value1`}
                  error={
                    touched.filter &&
                    touched.filter[findIndex("Title")]?.value1 &&
                    Boolean(helperText("Title")?.value1 as string | undefined)
                  }
                  helperText={
                    touched.filter &&
                    touched.filter[findIndex("Title")]?.value1 &&
                    (helperText("Title")?.value1 as string | undefined)
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={6} className="custom-col">
              <FormControl
                className="form-group mb-3 mb-md-4 d-flex flex-fill date-picker"
                variant="standard"
                error={
                  touched.filter &&
                  touched.filter[findIndex("EventDate")]?.value1 &&
                  Boolean(helperText("EventDate"))
                }
              >
                <InputLabel
                  className="form-label mb-2"
                  htmlFor="input-with-icon-adornment"
                >
                  Start Date
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd-MM-yyyy"
                    className="form-control"
                    slots={{ openPickerIcon: customIcon }}
                    value={values.filter[findIndex("EventDate")].value1}
                    minDate={new Date()}
                    onChange={(newValue) =>
                      setFieldValue(
                        `filter[${[findIndex("EventDate")]}].value1`,
                        newValue
                      )
                    }
                    slotProps={{
                      textField: {
                        placeholder: "DD-MM-YYYY",
                        inputProps: {
                          readOnly: true,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                {touched.filter && Boolean(helperText("EventDate")?.value1) && (
                  <FormHelperText error={true}>
                    {helperText("EventDate")?.value1 as string | undefined}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid xs={12} md={6} className="custom-col">
              <FormControl
                className="form-group mb-3 mb-md-4 d-flex flex-fill date-picker"
                variant="standard"
                error={
                  touched.filter &&
                  touched.filter[findIndex("EventDate")]?.value2 &&
                  Boolean(helperText("EventDate"))
                }
              >
                <InputLabel
                  className="form-label mb-2"
                  htmlFor="input-with-icon-adornment"
                >
                  End Date
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date()}
                    format="dd-MM-yyyy"
                    slots={{ openPickerIcon: customIcon }}
                    value={values.filter[findIndex("EventDate")].value2}
                    onChange={(newValue) =>
                      setFieldValue(
                        `filter[${[findIndex("EventDate")]}].value2`,
                        newValue
                      )
                    }
                    slotProps={{
                      textField: {
                        placeholder: "DD-MM-YYYY",
                        inputProps: {
                          readOnly: true,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                {touched.filter &&
                  // touched.filter[findIndex("EventDate")]?.value2 &&
                  Boolean(helperText("EventDate")?.value2) && (
                    <FormHelperText error={true}>
                      {helperText("EventDate")?.value2 as string | undefined}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>
        </div>

        {/* On edit profile screen */}
        <div className="form-block action-btns d-flex align-items-center justify-content-between">
          <Button className="btn btn-link btn-md" onClick={() => resetForm()}>
            Clear All
          </Button>
          <Button
            onClick={() => handleSubmit()}
            disabled={!(dirty && typeof errors.filter !== "string")}
            className="btn btn-primary btn-md"
            type="submit"
          >
            Apply Filter
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};
