export const GlobalDebug = (() => {
    const savedConsole = console;
  
    /**
     * @param debugOn {boolean}
     * @param suppressAll {boolean}
     */
    return (debugOn: boolean, suppressAll?: boolean) => {
      const suppress = suppressAll ?? false;
      if (!debugOn) {
        // suppress the default console functionality
        console.log = () => {};
        // suppress all types of consoles
        if (suppress) {
          console.info = () => {};
          console.warn = () => {};
          console.error = () => {};
        } else {
          console.info = savedConsole.info;
          console.warn = savedConsole.warn;
          console.error = savedConsole.error;
        }
      } else {
        // restore default console
        console.log = savedConsole.log;
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    };
  })();
  