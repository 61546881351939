import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import SeminarBookingsList from "sections/seminars/SeminarBookingsList";

const SeminarBookingsView = () => {
  const navigate = useNavigate();

  return (
    <Box className="product-list-section section">
      <Container className="custom-container">
        <Grid container className="custom-row">
          <Grid item xs={12} className="custom-col">
            <Card className="custom-card profile-card">
              <CardContent className="card-content p-0">
                <div className="card-header d-flex align-items-center mb-4 pb-3">
                  <div className="card-title flex-fill">
                    <Typography variant="h1">My Bookings</Typography>
                  </div>
                  <div className="card-action text-end">
                    <Button
                      className="btn btn-secondary btn-md"
                      onClick={() => navigate("/employee/seminars/list")}
                    >
                      <i className="mmm-arrow-left"></i>
                      <span className="d-none d-sm-flex">Back</span>
                    </Button>
                  </div>
                </div>
                <SeminarBookingsList />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SeminarBookingsView;
