// import { useEffect, useState } from "react";
import { PLASMIC } from "constants/Plasmic";
import Routes from "../src/routes/index";
import { BrowserRouter as Router } from "react-router-dom";
import ModalProvider from "mui-modal-provider";
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";
import { PlasmicRootProvider } from "@plasmicapp/loader-react";
import { useEffect } from "react";
import { GlobalDebug } from "utils/remove-consoles";
import useInactivityLogout from "hooks/useInactivityLogout";

const App = () => {
  const { isLoading } = useAuth();
  const { loading: logoutLoading } = useInactivityLogout();

  useEffect(() => {
    (process.env.REACT_APP_ENV === "PRODUCTION" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  if (isLoading || logoutLoading) return <Loader />;

  return (
    <PlasmicRootProvider loader={PLASMIC} disableLoadingBoundary={false}>
      <ModalProvider>
        {/* <AuthProvider> */}
        <Router>
          <Routes />
        </Router>
        {/* </AuthProvider> */}
      </ModalProvider>
    </PlasmicRootProvider>
  );
};

export default App;
