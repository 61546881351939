import { PRODUCTS } from "constants/General";
import { UserProfile } from "types/auth";
import { setCookies } from "./cookies";

const checkProductListing = (productName: string, employerDetails: UserProfile | null | undefined) => {
  const name = productName?.toLowerCase();  

  if (employerDetails?.EmployerName === "NHS" && (name === PRODUCTS.INVESTMENT_ADVICE || name === PRODUCTS.AVCWISE)) {
    return false;
  } else if (name === PRODUCTS.SNOOP && !employerDetails?.EnableSnoop) {
    return false;
  } else if (name === PRODUCTS.INVESTMENT_ADVICE && !employerDetails?.EnableInvestmentAdvice) { 
    return false;
  } else if (name === PRODUCTS.MORTGAGE_SERVICE && !employerDetails?.EnableMortgageService) {
    return false
  } else if (name === PRODUCTS.WILLSERVICE && !employerDetails?.EnableWillService) {
    return false
  } else if (name === PRODUCTS.AVCWISE && !employerDetails?.EnableAvcwise) {
    return false
  } else if (name === PRODUCTS.WEALTHIFY && !employerDetails?.EnableWealthify) {
    return false
  } 
    else {
    return true;
  }

}

const checkEmployerSubDomain = (employerSubDomain: string, orgRoleId?: string, employerId?: string) => {
  const originSubdomain = window.location.hostname?.split(".")[0];
  const expectedSubDomain = employerSubDomain?.toString()?.toLowerCase();
  const genericSubDomain = process.env.REACT_APP_GENERIC_URL?.split(".")[0];
  const appGenericURL = `https://${process.env.REACT_APP_GENERIC_URL}`;
  const expectedOriginURL = `https://${expectedSubDomain}.${process.env.REACT_APP_GENERIC_URL}`;
  const isLocalEnvironment = process.env.REACT_APP_ENV === "local";

  if (originSubdomain === expectedSubDomain) {
    return;
  }

  if (!isLocalEnvironment && expectedSubDomain && originSubdomain !== expectedSubDomain) {
    setCookies('orgRoleId', orgRoleId)
    setCookies('employerId', employerId)
    window.location.href = expectedOriginURL;
  } else if (!isLocalEnvironment && !expectedSubDomain && originSubdomain !== genericSubDomain) {
    setCookies('orgRoleId', orgRoleId)
    setCookies('employerId', employerId)
    window.location.href = appGenericURL;
  }
};

export { checkProductListing, checkEmployerSubDomain };