import { Backdrop } from "@mui/material";
import LottieLoader from "./LottieLoader";

const FetchLoader = () => {
  return (
    // <Box
    //   display={"flex"}
    //   flex={1}
    //   sx={{
    //     justifyContent: "center",
    //     alignItems: "center",
    //     width: { xs: "100vw", md: "100vw", lg: "80vw", xl: "80vw" },
    //     height: "100vh",
    //   }}
    // >
    //   <CircularProgress sx={{ color: "#391474" }} size={80} />
    // </Box>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <LottieLoader />
    </Backdrop>
  );
};

export default FetchLoader;
