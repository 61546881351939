import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  InputLabel
} from "@mui/material";

import { CSVLink } from "react-csv";
import { ColumnInstance, HeaderGroup, Row, SortingRule } from "react-table";

import {
  CaretUpOutlined,
  CaretDownOutlined,
  CheckOutlined,
} from "@ant-design/icons";

interface HeaderSortProps {
  column: HeaderGroup<{}>;
  sort?: boolean;
  handleSort?: () => void;
}

interface LabelKeyObject {
  label: string;
  key: string;
}

type Headers = LabelKeyObject[] | string[];

export const HeaderSort = ({ column, sort, handleSort }: HeaderSortProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box sx={{ width: "max-content" }}>{column.render("Header")}</Box>
      {!column.disableSortBy && (
        <Stack
          sx={{ color: "secondary.light" }}
          {...(sort && {
            ...column.getHeaderProps(column.getSortByToggleProps()),
          })}
          onClick={handleSort}
        >
          <CaretUpOutlined
            style={{
              fontSize: "0.625rem",
              color:
                column.isSorted && !column.isSortedDesc
                  ? theme.palette.text.secondary
                  : "inherit",
            }}
          />
          <CaretDownOutlined
            style={{
              fontSize: "0.625rem",
              marginTop: -2,
              color: column.isSortedDesc
                ? theme.palette.text.secondary
                : "inherit",
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

interface HidingSelectProps {
  allColumns: ColumnInstance<{}>[];
  hiddenColumns: string[];
  setHiddenColumns: (param: string[]) => void;
}

export const HidingSelect = ({
  hiddenColumns,
  setHiddenColumns,
  allColumns,
}: HidingSelectProps) => {
  const handleChange = (event: SelectChangeEvent<typeof hiddenColumns>) => {
    const {
      target: { value },
    } = event;

    setHiddenColumns(typeof value === "string" ? value.split(",") : value!);
  };

  const theme = useTheme();

  return (
    <FormControl sx={{ width: 250 }}>
      <Select
        id="column-hiding"
        multiple
        displayEmpty
        value={hiddenColumns}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-column-hiding"
            placeholder="select column"
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography variant="subtitle1">Show / Hide Columns</Typography>
            );
          }

          if (selected.length > 0 && selected.length === allColumns.length) {
            return (
              <Typography variant="subtitle1">Show / Hide Columns</Typography>
            );
          }

          return (
            <Typography variant="subtitle1">Show / Hide Columns</Typography>
          );
        }}
        MenuProps={MenuProps}
        size="small"
      >
        {allColumns.map((column: ColumnInstance) => {
          let ToggleChecked =
            column.id === "#"
              ? true
              : hiddenColumns!.indexOf(column.id) > -1
                ? false
                : true;
          return (
            <MenuItem
              key={column.id}
              value={column.id}
              sx={{
                "&.Mui-selected": { bgcolor: "background.paper" },
                whiteSpace: "normal",
                alignItems: "flex-start",
              }}
            >
              <Checkbox
                checked={ToggleChecked}
                color="primary"
                checkedIcon={
                  <Box
                    className="icon"
                    sx={{
                      width: 16,
                      height: 16,
                      border: "1px solid",
                      borderColor: "inherit",
                      borderRadius: 0.25,
                      position: "relative",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <CheckOutlined
                      className="filled"
                      style={{
                        position: "absolute",
                        color: theme.palette.common.white,
                        fontSize: "14px",
                        top: "0px",
                        left: "0px",
                      }}
                    />
                  </Box>
                }
              />
              <ListItemText
                primary={
                  typeof column.Header === "string"
                    ? column.Header
                    : column?.title
                }
                sx={{ paddingTop: "6px" }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

interface SortingSelectProps {
  sortBy: string;
  setSortBy: (sortBy: SortingRule<{}>[]) => void;
  allColumns: ColumnInstance<{}>[];
}

export const SortingSelect = ({
  sortBy,
  setSortBy,
  allColumns,
}: SortingSelectProps) => {
  const [sort, setSort] = useState<string>(sortBy);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSort(value);
    setSortBy([{ id: value, desc: false }]);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        id="column-hiding"
        displayEmpty
        value={sort}
        onChange={handleChange}
        input={
          <OutlinedInput id="select-column-hiding" placeholder="Sort by" />
        }
        renderValue={(selected) => {
          const selectedColumn = allColumns.filter(
            (column: ColumnInstance) => column.id === selected
          )[0];
          if (!selected) {
            return <Typography variant="subtitle1">Sort By</Typography>;
          }

          return (
            <Typography variant="subtitle2">
              Sort by (
              {typeof selectedColumn.Header === "string"
                ? selectedColumn.Header
                : selectedColumn?.title}
              )
            </Typography>
          );
        }}
        size="small"
      >
        {allColumns
          .filter((column: ColumnInstance) => column.canSort)
          .map((column: ColumnInstance) => (
            <MenuItem key={column.id} value={column.id}>
              <ListItemText
                primary={
                  typeof column.Header === "string"
                    ? column.Header
                    : column?.title
                }
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

interface CSVExportProps {
  data: never[] | any[];
  filename: string;
  headers?: Headers;
}

export const CSVExport = ({ data, filename, headers }: CSVExportProps) => {
  return (
    <CSVLink data={data} filename={filename} headers={headers}>
      <Button className="btn btn-secondary btn-md">Export</Button>
    </CSVLink>
  );
};

interface EcoTablePaginationI {
  gotoPage: (value: number) => void;
  setPageSize: (value: number) => void;
  pageIndex: number;
  pageSize: number;
  rows: Row[];
  totalRecords: number;
}

export const EcoTablePagination = ({
  gotoPage,
  rows,
  setPageSize,
  pageSize,
  pageIndex,
  totalRecords,
}: EcoTablePaginationI) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    gotoPage(value);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "auto" }}
      className="custom-table-footer-container"
    >
      <Grid item>
        <Stack
          className="custom-table-rows"
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              className="footer-text"
              variant="caption"
              color="secondary"
            >
              Row per page
            </Typography>
            <FormControl className="form-froup" sx={{ m: 1 }} aria-labelledby="demo-controlled-open-select">
              <InputLabel id={'label-for-page-count'} sx={{ display: "none" }} htmlFor={'category-select-input-id'}>{"Count"}</InputLabel>
              <Select
                id="demo-controlled-open-select"
                label=""
                labelId="label-for-page-count"
                open={open}
                aria-label="Choose an option"
                onClose={handleClose}
                onOpen={handleOpen}
                value={pageSize}
                onChange={handleChange}
                size="small"
                className="form-control"
                inputProps={{ 'id': 'category-select-input-id' }}
                SelectDisplayProps={{
                  "aria-required": true
                }}
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                {[5, 10, 15, 20, 25, 30].map((res) => (
                  <MenuItem key={res} value={res}>
                    {res}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography
            className="footer-text"
            variant="caption"
            color="secondary"
          >
            Go to
          </Typography>
          <InputLabel aria-label="Page No" htmlFor={'page-input-id'} />
          <TextField
            id="page-input-id"
            size="small"
            type="number"
            // aria-label="page-input-id"
            className="form-control"
            value={pageIndex}
            onChange={(e) => {
              const page =
                e.target.value && Number(e.target.value) >= 1
                  ? Number(e.target.value)
                  : 1;
              gotoPage(page);
            }}
            sx={{
              "& .MuiOutlinedInput-input": { py: 0.75, px: 1.25, width: 36 },
            }}
            aria-required={true}
            inputProps={{ 'id': 'page-input-id' }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: 0 }}>
        <Pagination
          className="custom-table-pagination"
          count={Math.ceil(totalRecords / pageSize)}
          page={pageIndex}
          onChange={handleChangePagination}
          color="primary"
          variant="outlined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};
