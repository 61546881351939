import { Box, Button, Pagination, Typography } from "@mui/material";
import FetchLoader from "components/FetchLoader";
import { useFilter } from "hooks/useFilter";
import moment from "moment";
import CancelBooking from "./CancelBooking";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import NoDataFound from "components/NoDataFound";
import { useSelector } from "store";
import { useCancelBookingSeminarMutation, useViewMyBookingsQuery } from "store/reducers/seminar";
import { Seminar } from "types/seminar";

const SeminarBookingsList = () => {
  const { filter, setsearchParams } = useFilter();
  const pageNo = filter("page") ? parseInt(filter("page")) : 1;
  const size = filter("pageSize") ? parseInt(filter("pageSize")) : 30;
  const { user } = useSelector((store) => store.auth);
  const { isFetching, data: responseBody } = useViewMyBookingsQuery(
    { pageNo, size, EmployerID: user?.EmployerID ?? "" },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data = [], totalRecords = 0, pageSize = 0 } = responseBody ?? {};
  const count = Math.ceil(totalRecords / pageSize);
  const [cancelSeminar, { data: joinBookingData, isError, isSuccess, error }] =
    useCancelBookingSeminarMutation();

  useEffect(() => {
    if (isError || isSuccess) {
      enqueueSnackbar(
        (error as string | (string & any[])) ||
          (joinBookingData?.message as string),
        {
          variant: isSuccess ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinBookingData?.message, error, isError, isSuccess]);

  const onChangePageIndex = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setsearchParams("page", value.toString());
  };

  const onJoinBooking = async (seminar: Seminar) => {
    const { IsBooked, attendeeID } = seminar;
    if (IsBooked && attendeeID) {
      const saveValues = {
        ID: attendeeID,
        IsBooked: true,
      };
      await cancelSeminar(saveValues);
    }
  };

  if (isFetching) return <FetchLoader />;
  if (!data.length) return <NoDataFound />;

  return (
    <>
      <Box className="event-detail_list">
        {data?.map((res) => {
          const startTime = moment(res.StartTime);
          const formNow = startTime.diff(moment(), "minutes");
          return (
            <Box className="event-detail_block booking_block" key={res.ID}>
              <Box className="event-detail_card d-flex flex-wrap flex-lg-nowrap">
                <Box className="event-card_info" sx={{maxWidth: "150px"}}>
                  <Typography variant="h3">Seminar topic</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {res.Title}
                  </Typography>
                </Box>
                <Box className="event-card_info">
                  <Typography variant="h3">Date & Time</Typography>
                  <Typography variant="body1">
                    {moment(res.StartDate).format("DD-MM-YYYY")} |{" "}
                    {startTime.format("LT")}
                  </Typography>
                </Box>
                <Box className="event-card_action ms-lg-auto d-flex flex-column justify-content-center">
                  {formNow <= 10 ? (
                    <Button
                      className="btn btn-purple btn-sm"
                      onClick={() => onJoinBooking(res)}
                    >
                      <span>Join Now</span>
                    </Button>
                  ) : (
                    <CancelBooking seminarID={res.attendeeID} />
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {count > 1 && (
        <Pagination
          className="custom-pagination"
          count={count}
          onChange={onChangePageIndex}
        />
      )}
    </>
  );
};

export default SeminarBookingsList;
