import { Box, Button, Card, CardContent, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import SeminarDetailView from "sections/seminars/SeminarDetailView";

const SeminarDetail = () => {
  const navigate = useNavigate();
  return (
    <Box className="product-list-section section">
      <Container className="custom-container">
        <Grid container className="custom-row">
          <Grid item xs={12} className="custom-col">
            <Card className="custom-card profile-card">
              <CardContent className="card-content p-0">
                <div className="card-header d-flex align-items-center justify-content-end mb-4 pb-2 pb-lg-3">
                  <div className="card-action text-end">
                    <Button
                      className="btn btn-secondary btn-md"
                      onClick={() => navigate(-1)}
                    >
                      <i className="mmm-arrow-left"></i>
                      <span className="d-none d-sm-flex">Back</span>
                    </Button>
                  </div>
                </div>
                <SeminarDetailView />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SeminarDetail;
