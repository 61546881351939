import { Grid, Box, Container } from "@mui/material";
import { ReactNode } from "react";

export type PropType = {
  children: ReactNode;
};

const EventBanner = ({ children }: PropType) => {
  return (
    <Box className="event_section">
      <Container className="custom-container">
        <Grid container className="custom-row justify-content-center">
          <Grid xs={12} className="custom-col">
            <Box className="event-banner_wrapper d-flex align-items-center flex-wrap flex-lg-nowrap">
              {children}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EventBanner;
