import Cookies from 'universal-cookie';

const cookies = new Cookies();

const options = {
  domain: '.my-money-matters.co.uk',
  path: '/'
}

const getCookies = (key: string) => {
  //@ts-ignore
  return cookies.get(key, options);
}

const setCookies = (key: string, value: string | boolean | number | undefined) => {
  cookies.set(key, value, options);
}

export const removeCookie = (name: string) => {
  cookies.remove(name, options);
};


export {
  getCookies,
  setCookies
}