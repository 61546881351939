import { useLocation, useNavigate } from "react-router";
import { SelectChangeEvent } from "@mui/material/Select";
import { useMemo, useState, useCallback } from "react";
import { useFilter } from "../../hooks/useFilter";
import ReactTable from "../../components/ReactTable";
import { useSelector } from "store";
import { EmployeeRowInfo } from "types/employee";
import moment from "moment";
import { HeaderGroup } from "react-table";
import { UserSwitchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Box,
  InputLabel,
  FormControl,
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {
  useGetAllProductWiseEmployeeListQuery,
  useLazyDownloadSampleDashboardEmployeeFileQuery,
} from "store/reducers/dashboard";
import downloadFile from "helper/downloadFile";

const DashboardEmployeeList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const auth = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState("FirstName");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [showPastEmployees, setShowPastEmployees] = useState(false);
  const [prodName, setProdName] = useState<string[]>(
    location?.state?.productName
  );
  const { filter, setsearchParams } = useFilter();
  const pageIndex = filter("page") ? parseInt(filter("page")) : 1;
  const pageSize = filter("pageSize") ? parseInt(filter("pageSize")) : 30;
  const [trigger] = useLazyDownloadSampleDashboardEmployeeFileQuery();
  const { StartDate, EndDate } = useSelector((state) => state?.filter?.date);
  const { data, isFetching } = useGetAllProductWiseEmployeeListQuery(
    {
      pageNo: pageIndex,
      size: pageSize,
      searchText: searchText,
      EmployerID: auth.user?.EmployerID,
      RoleID: auth.user?.RoleID,
      StartDate: StartDate
        ? moment(StartDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      EndDate: EndDate
        ? moment(EndDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      sortColumn,
      sortOrder,
      ProductID: prodName,
    },
    { refetchOnMountOrArgChange: true }
  );

  const FirstNameTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;

    return (
      <>
        {row?.values?.FirstName ? row?.values?.FirstName : "-"}
        {row?.original?.IsAdminForAnyOrg === true && (
          <UserSwitchOutlined className="admin-icon" />
        )}
      </>
    );
  }, []);

  const EmailTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return <>{row?.values?.Email ? row?.values?.Email : "-"}</>;
  }, []);

  const EmployeeNoTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return (
      <>{row?.values?.EmployeeNumber ? row?.values?.EmployeeNumber : "-"}</>
    );
  }, []);

  const NiNoTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return <>{row?.values?.NiNumber ? row?.values?.NiNumber : "-"}</>;
  }, []);

  const LeaveDateTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return (
      <>
        {row?.values?.LeaveDate
          ? moment(row?.values?.LeaveDate).format("DD-MM-YYYY")
          : row?.values?.LastLogin
            ? moment(row?.values?.LastLogin).format("DD-MM-YYYY")
            : "-"}
      </>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "FirstName",
        Cell: FirstNameTypeComponent,
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
      {
        Header: "Email Address",
        accessor: "Email",
        Cell: EmailTypeComponent,
      },
      {
        Header: "Employee Number",
        accessor: "EmployeeNumber",
        Cell: EmployeeNoTypeComponent,
      },
      {
        Header: "NI Number",
        accessor: "NiNumber",
        Cell: NiNoTypeComponent,
      },
      {
        Header: "Last Login",
        accessor: "LastLogin",
        Cell: LeaveDateTypeComponent,
      },
      {
        Header: "Product Enrolled",
        accessor: "Products",
      },
    ],
    [
      EmailTypeComponent,
      EmployeeNoTypeComponent,
      FirstNameTypeComponent,
      LeaveDateTypeComponent,
      NiNoTypeComponent,
    ]
  );

  const onSearchData = (val: string) => {
    if (val) {
      setSearchText(val);
    } else {
      setSearchText("");
    }
    setsearchParams("page", "1");
  };

  const onChangePageIndex = (index: number) => {
    setsearchParams("page", index.toString());
  };

  const setPageSize = (index: number) => {
    setsearchParams("pageSize", index.toString());
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSort = (column: HeaderGroup) => {
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    setSortColumn(column.id);
  };

  const handleCheckboxClick = () => {
    setShowPastEmployees(!showPastEmployees);
  };

  const showCheckbox = () => {
    return (
      <FormControlLabel
        value="Show Past Employees"
        control={<Checkbox onChange={handleCheckboxClick} />}
        label="Show Past Employees"
        labelPlacement="end"
        sx={{ mr: 1 }}
      />
    );
  };

  const handleChange = (event: SelectChangeEvent<typeof prodName>) => {
    const {
      target: { value },
    } = event;
    setProdName(typeof value === "string" ? value.split(",") : value);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const downloadSampleFile = async () => {
    const { data } = await trigger({
      EmployerID: auth.user?.EmployerID,
      RoleID: auth.user?.RoleID,
      StartDate: location?.state?.startDate,
      EndDate: location?.state?.endDate,
      ProductID: location?.state?.productName ?? [],
    });
    const sampleFileLink = data?.data
      ? `data:text/csv;base64,${data?.data}`
      : "";
    downloadFile(sampleFileLink, "Product Wise Employee");
  };

  const downloadCSVFile = () => {
    downloadSampleFile();
  };

  return (
    <Box className="product-list-section section">
      <Container className="custom-container">
        <Grid container className="custom-row">
          <Grid item xs={12} className="custom-col">
            <Card className="custom-card profile-card">
              <CardContent className="card-content p-0">
                <div className="card-header d-flex align-items-center flex-wrap mb-4 pb-2 pb-lg-3">
                  <div className="card-title flex-fill">
                    <Typography variant="h1">Product Wise Employees</Typography>
                  </div>
                  <div className="card-action text-end d-flex flex-wrap autoInput_textwrapper flex-fill justify-content-end">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="label-for-products" htmlFor={'input-id'}>Products</InputLabel>
                      <Select
                        id="product-select"
                        labelId="label-for-products"
                        aria-describedby="select-helper-text"
                        className="autoInput_text"
                        multiple
                        value={prodName}
                        onChange={handleChange}
                        inputProps={{id: 'input-id'}}
                        renderValue={(selected) =>
                          selected?.length > 0
                            ? "Selected Product : " + selected?.length
                            : "Select Product"
                        }
                        MenuProps={MenuProps}
                        placeholder="Products"
                      >
                        {location?.state?.productList?.map((prod: any, index: number) => (
                          <MenuItem
                            key={prod?.ProductId}
                            value={prod?.ProductId}
                          >
                            <Checkbox
                              checked={prodName.indexOf(prod?.ProductId) > -1}
                            />
                            <ListItemText primary={prod?.ProductName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      className="btn btn-secondary btn-md"
                      sx={{ marginLeft: 2 }}
                      onClick={downloadCSVFile}
                    >
                      <span>Export</span>
                      <i className="mmm-export2"></i>
                    </Button>
                    <Button
                      className="btn btn-secondary btn-md"
                      sx={{ ml: 2 }}
                      onClick={() => navigate("/employee/webinars/theme/list")}
                    >
                      <i className="mmm-arrow-left"></i>
                      <span>Back</span>
                    </Button>
                  </div>
                </div>
                <ReactTable
                  columns={columns}
                  data={data?.data}
                  setGlobalFilter={onSearchData}
                  pageNumber={pageIndex}
                  isFetching={isFetching}
                  totalRecords={data?.totalRecords || 0}
                  onChangePageIndex={onChangePageIndex}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  open={open}
                  handleToggle={handleToggle}
                  handleSort={handleSort}
                  showCheckbox={showCheckbox()}
                ></ReactTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardEmployeeList;
