import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

import { BookSeminar, BookSeminarData, CheckAvaliblityParam, GetSeminarInfo, SearchParam, SemianrList, UpdateSeminarInfo } from "types/seminar";

export const SEMINAR_API_REDUCER_KEY = "seminar";

export const seminarApi = createApi({
  reducerPath: SEMINAR_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Seminar"],
  endpoints: (builder) => ({
    getAllSeminar: builder.query<SemianrList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, EmployerID, filter } = args;
        const body = { pageNo, size, employerID: EmployerID };
        return {
          url: ApiConstant.GET_ALL_SEMINAR,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body },
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.pageNo) {
          delete newQueryArgs.pageNo;
        }
        if (!newQueryArgs.hasOwnProperty("filter")) {
          newQueryArgs["filter"] = [];
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.data) {
          const filterNewData = newItems.data.filter(
            (res) => !currentCache.data.find((d) => d.ID === res.ID)
          );
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...filterNewData],
          };
        }
        return newItems;
      },
      providesTags: ["Seminar"],
    }),
    getCheckAvliablity: builder.query<
      SemianrList,
      Partial<CheckAvaliblityParam>
    >({
      query: (body: CheckAvaliblityParam) => {
        return {
          url: ApiConstant.GET_SEMINAR_CHECKAVLIABLITY,
          method: "POST",
          body,
        };
      },
      providesTags: ["Seminar"],
    }),
    bookSeminar: builder.mutation<BookSeminarData, Partial<BookSeminar>>({
      query(body) {
        return {
          url: ApiConstant.BOOK_SEMINAR,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Seminar"],
    }),
    cancelBookingSeminar: builder.mutation<
      UpdateSeminarInfo,
      Partial<GetSeminarInfo>
    >({
      query({ ID, ...body }) {
        return {
          url: `${ApiConstant.CANCEL_SEMINAR}/${ID}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Seminar"],
    }),
    viewMyBookings: builder.query<BookSeminarData, Partial<SearchParam>>({
      query: (args) => {
        const { pageNo = 1, size = 30, EmployerID } = args;
        const body = { pageNo, size, employerID: EmployerID };
        return {
          url: ApiConstant.VIEW_MY_SEMINAR_BOOKINGS,
          method: "POST",
          body,
        };
      },
      providesTags: ["Seminar"],
    })
  }),
});

export const {
  useGetAllSeminarQuery,
  useLazyGetAllSeminarQuery,
  useBookSeminarMutation,
  useCancelBookingSeminarMutation,
  useViewMyBookingsQuery,
  useGetCheckAvliablityQuery,
} = seminarApi;
