import { useLocation, Navigate } from "react-router-dom";

import {
  ADMIN_APP_DEFAULT_PATH,
  EMPLOYEE_APP_DEFAULT_PATH,
} from "../../config";
import useAuth from "../../hooks/useAuth";

import { GuardProps } from "../../types/auth";

const UnauthorizeGuard = ({ children }: GuardProps) => {
  const { user, error } = useAuth();
  const location = useLocation();

  if (!error) {
    const default_path =
      user?.RoleName === "Employee"
        ? EMPLOYEE_APP_DEFAULT_PATH
        : ADMIN_APP_DEFAULT_PATH;
    const url = location?.state?.from ? location?.state?.from : default_path;
    return <Navigate to={url} state={{ from: location }} replace />;
  }

  return children;
};

export default UnauthorizeGuard;
