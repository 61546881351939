import { useLocation, Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

import { GuardProps } from "../../types/auth";
import Loader from "components/Loader";

const RoleGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, error, isLoggingIn, isLoggingOut, logout } = useAuth();
  const location = useLocation();
  const queryParams = localStorage.getItem('params');
  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (queryParams === "true") {
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (error) {
    return (
      <Navigate to="/unauthorized-access" state={{ from: location }} replace />
    );
  }

  if (isLoggedIn && !user?.RoleID && !isLoggingIn) {
    return <Navigate to="/select-role" state={{ from: location }} replace />;
  }

  return children;
};

export default RoleGuard;
