import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./LottieJson.json";

const LottieLoader = () => {
  return (
    <Player
      src={animationData}
      className="player"
      loop
      autoplay
      style={{ height: "100px", width: "100px" }}
    />
  );
};

export default LottieLoader;
