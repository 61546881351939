import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { ApiConstant } from 'constants/ApiConstants'
import { RootState, store } from 'store'
import { setlogout } from './login';
// import { auth0Client } from 'contexts/Auth0Context';
import { LOGOUT } from './actions';
import captureSentryError from 'helper/captureSentryError';


const baseQuery = fetchBaseQuery({
  baseUrl: ApiConstant.BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const token = (getState() as RootState).auth.token

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})


const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (!result.error) {
    return result;
  }

  const error = (result.error.data || result.error) as { statusCode?: number, status?: number };

  if (error.statusCode === 401 || error.status === 401) {
    store.dispatch(setlogout())
    store.dispatch({
      type: LOGOUT
    });
    window.location.href = '/login'
  }

  captureSentryError(result.error, args, api);

  return result;
}

export default baseQueryWithReauth