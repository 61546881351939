import { EMPLOYEE_APP_DEFAULT_PATH, WEBINAR_PATH } from "config"
import { getCookies } from "helper/cookies"
import { isEmpty } from "lodash"
export const getPathFromInternalLink = (redirectedURl: string) => {
    let path = ''
    if (redirectedURl.includes("/employee/homeavc")) {
        path = 'home'
    } else if (redirectedURl.includes("/employee/manageavc")) {
        path = 'manage_my_shared_cost_avc'
    } else if (redirectedURl.includes("/employee/my-investment-advice")) {
        path = 'my_investment_advice'
    } else if (redirectedURl.includes("/employee/new-app-form")) {
        path = 'new_application'
    }
    return path
}

function dynamicallyCreateAnchorAndNavigate(url: string) {
    let anchorElement = document.createElement('a');
    anchorElement.href = url;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }
  
  function openInNewTab(url: string){
    dynamicallyCreateAnchorAndNavigate(url);
  }

export const navigateToInternalLink = (path: string) => {
    const employeeDetails = getCookies("EmployeeDetails") ?? "";
    const parsedEmployeeDetails = employeeDetails;
    const employerSubDomain = parsedEmployeeDetails?.SubDomain;
    const client_id = process.env.REACT_APP_SILENT_LOGIN_CLIENT_ID;
    const callback = employerSubDomain ? `https://${employerSubDomain}${process.env.REACT_APP_AUTH_DEFAULT_CALLBACK}` : process.env.REACT_APP_AUTH_CALLBACK;
    const avcAmount = localStorage.getItem("avcAmount")
    const redirect_uri = `${callback}?path=${path}`;
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    const authUrl = `https://${domain}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid profile email&prompt=none${avcAmount ? `&state=avc_amount=${avcAmount}`: '' }`;
    openInNewTab(authUrl)
    localStorage.removeItem('baseURl')
    localStorage.removeItem('avcAmount')
}

export const getBaseURL = () => {
    let baseURl = localStorage.getItem("baseURl") ?? EMPLOYEE_APP_DEFAULT_PATH
    const deepURL = getPathFromInternalLink(baseURl)
    if (!isEmpty(deepURL)) {
        navigateToInternalLink(deepURL)
    }
    if (baseURl === "/employee/events") {
        baseURl = WEBINAR_PATH
        // localStorage.removeItem('baseURl')
    }
    return baseURl
}

export const getThemeScoreStatus = (score: number) => {
    if (score <= 60) {
        return "Requires attention"
    }
    else if (score > 61 && score <= 89) {
        return "Room for improvement"
    }
    else {
        return "On track"
    }
}

export const getTotalScoreStatus = (score: number) => {
    if (score <= 60) {
        return "#FF0000"
    }
    else if (score > 61 && score <= 89) {
        return "#FFBF00"
    }
    else {
        return "#00FF00"
    }
}

export const getTotalScoreStatusColor = (score: number) => {
    if (score <= 60) {
        return "#E56180"
    }
    else if (score > 61 && score <= 89) {
        return "#BD73E1"
    }
    else {
        return "#767BDD"
    }
}

export function doesUrlMatchProductPagePattern(url: string): boolean {
    //const pattern = `https://${process.env.REACT_APP_GENERIC_URL}/employee/product/*`
    // Escape special characters in the pattern, except for *
    const pattern = '/employee/product/';

    // Check if the URL starts with the defined pattern
    return url.startsWith(pattern);
}

export const getPlasmicTagName = () => {
    const environemnt = process.env.REACT_APP_ENV
    if (environemnt === 'DEVELOPMENT') {
        return 'development'
    } else if (environemnt === 'STAGING') {
        return 'staging'
    } else if (environemnt === 'PRODUCTION') {
        return 'production'
    }
}