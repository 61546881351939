import useAuth from "../../hooks/useAuth";
import Loader from "components/Loader";
import { getBaseURL } from "utils/utils";
import { GuardProps } from "../../types/auth";
import { Navigate, useLocation } from "react-router";
import { ADMIN_APP_DEFAULT_PATH, EMPLOYEE_APP_DEFAULT_PATH } from "config";

const BaseRoleGuard = ({ children }: GuardProps) => {
  const { user, isLoggingOut, isLoggingIn, isLoggedIn } = useAuth();
  const location = useLocation();
  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }

  if (user?.RoleName === "Employee" && isLoggedIn) {
    const baseURl = getBaseURL()
    console.log("BaseGuard baseUrl" ,baseURl)
    return <Navigate state={{ from: location }} to={baseURl} replace />;
  } else {
    return <Navigate state={{ from: location }} to={ADMIN_APP_DEFAULT_PATH} replace />;
  }
};

export default BaseRoleGuard;
