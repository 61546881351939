import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthProps } from '../../types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  FirstName: '',
  LastName: '',
  isLoggingOut: false,
  isLoggingIn: false
};


// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to set the user when authenticated
    setUser: (state, action: PayloadAction<AuthProps>) => {
      state.user = action.payload.user
    },
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;

      state.token = token
    },
    setLogin: (state, action: PayloadAction<AuthProps>) => {
      const { isLoggedIn = false, isInitialized = false, user, token } = action.payload
      state.isLoggedIn = isLoggedIn
      state.isInitialized = isInitialized
      state.token = token
      state.user = {...state.user, ...user}
    },
    setAuthRole: (state, action: PayloadAction<AuthProps>) => {
      const { user, FirstName, LastName } = action.payload
      state.isInitialized = true
      state.FirstName = FirstName
      state.LastName = LastName
      state.user = {...state.user, ...user}
    },
    // Action to log out the user
    setLogoutStatus: (state, action: PayloadAction<Partial<AuthProps>>) => {
      state.isLoggingOut = action.payload.isLoggingOut
    },
    setLoggingInStatus: (state, action: PayloadAction<Partial<AuthProps>>) => {
      state.isLoggingIn = action.payload.isLoggingIn
    },
    setlogout: (state) => {
      state.isLoggedIn = false
      state.isLoggingOut = false
      state.isInitialized = false
      state.user = null
      state.token = null
    },
  },
});

// Export the reducer and actions
export const { setUser, setlogout, setLogin, setAuthRole, setLogoutStatus, setLoggingInStatus, setToken} = authSlice.actions;
export default authSlice.reducer;
