import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import { ManageProductData, ProductInfo, ProductConsentPayload, ProductConsentResponse } from "../../types/product";
import baseQuery from "./baseQuery";

export const PRODUCT_API_REDUCER_KEY = "productApi";

export const productApi = createApi({
  reducerPath: PRODUCT_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    getAllProducts: builder.query<ManageProductData, Partial<ProductInfo>>({
      query: (body: ProductInfo) => {
        const { EmployerID, RoleID } = body;
        return {
          url: ApiConstant.GET_ALL_PRODUCTS,
          method: "POST",
          body: { EmployerID, RoleID },
        };
      },
      providesTags: ["Product"],
    }),
    updateNudgeConsent: builder.mutation<ProductConsentResponse, ProductConsentPayload>({
      query: (body: ProductConsentPayload) => {
        return {
          url: ApiConstant.NUDGE_CONSENT,
          method: "POST",
          body: body
        };
      },
      invalidatesTags: ["Product"],
    })
  }),
});

export const { useGetAllProductsQuery, useUpdateNudgeConsentMutation } = productApi;
